import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { pageContext } from '../../../../types'
import SEO from '~/utils/seo'
import { TimesPage } from '~/components/pages/TimesPage'

export default function OnsenTimesPage({ location, data, pageContext }: PageProps<GatsbyTypes.OnsenTimesPageQuery, pageContext>) {
    return (
        <>
            <SEO
                title='お風呂の情報発信メディア - Onsen* Times | Onsen*'
                description='Onsen*のサービスや製品に関する最新情報やメディア掲載情報、イベント情報やお風呂にまつわる記事など多種多様なニュースを掲載しています。'
                robots='all'
                image={data.allMicrocmsNews.edges[0].node.thumbnail.url}
                url={location.href}
            />
            <TimesPage data={data} pageContext={pageContext} pathName={'/times/page'}/>
        </>
    )
}

export const query = graphql`
	query OnsenTimesPage($skip: Int!, $limit: Int!) {
		allMicrocmsNews(skip: $skip, limit: $limit, sort: {fields: postDate, order: DESC}) {
              edges {
                  node {
                      title
                      contents
                      newsId
                      thumbnail {
                          url
                      }
                      publishedAt(formatString: "YYYY.MM.DD")
                      postDate(formatString: "YYYY.MM.DD")
                  }
              }
        }
	}
`
